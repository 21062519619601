<template>
    <v-content>
        <v-container fluid>
            <v-expansion-panels multiple hover>
                <v-expansion-panel>
                    <v-expansion-panel-header>{{$t('filter')}}</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-container fluid>
                            <v-row col="12">
                                <v-row align="start" justify="start" class="mt-4">
                                    <v-col class="d-flex" cols="12" sm="6">
                                        <v-select :items="customYear"
                                                  :label="$t('from_date')"
                                                  v-model="serverParams.FromDate"
                                                  dense
                                                  outlined
                                                  prepend-inner-icon="event"></v-select>
                                    </v-col>

                                    <v-col class="d-flex" cols="12" sm="6">
                                        <v-select :items="customYear"
                                                  :label="$t('to_date')"
                                                  v-model="serverParams.ToDate"
                                                  prepend-inner-icon="event"
                                                  dense
                                                  outlined></v-select>
                                    </v-col>

                                    <v-col class="d-flex" cols="12" sm="12">
                                        <v-spacer></v-spacer>
                                        <v-btn class="mx-2" dark small color="primary" @click="loadItems">
                                            <v-icon dark> search</v-icon>
                                            {{$t('search')}}
                                        </v-btn>
                                        <!--<v-btn @click.stop="onDownload" small class="success float-right mx-2">
                                            <v-icon>get_app</v-icon>
                                            {{$t('download_to_excel')}}
                                        </v-btn>-->
                                        <v-spacer></v-spacer>
                                    </v-col>
                                </v-row>
                            </v-row>
                        </v-container>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <div class="my-4 d-flex justify-end" v-if="rows.length > 0">
                <JsonExcelVue :fetch="fetchData" is-custom-header is-custom-data :customHeader="customHeader">
                                            <v-btn small class="success mx-4">
                                                <v-icon>get_app</v-icon>
                                                {{$t('download_to_excel')}}
                                            </v-btn>

                                        </JsonExcelVue>
            </div>

            <div class="table-container">
                <table class="myCustomTable scrollbar-md" v-if="yearsBetween.length && tableDataLoaded">
                    <thead>
                        <tr>
                            <th class="sticky-table-data-right-first sticky-table-data-top-first z-4 bg-blue" rowspan="2">District</th>
                            <th class="sticky-table-data-right-second sticky-table-data-top-first z-4 bg-blue" rowspan="2">Type</th>
                            <th colspan="3"
                                v-for="(year,yearKey) in yearsBetween"
                                :key="yearKey"
                                class="sticky-table-data-top-first">
                                {{year}}
                            </th>
                        </tr>
                        <tr>
                            <template v-for="(year,yearColumnIndex) in yearsBetween">
                                <th class="sticky-table-data-top-second" :key="year+'_affiliated_house_'+yearColumnIndex">No. of Affiliated Homestay</th>
                                <th class="sticky-table-data-top-second" :key="year+'_no_of_rooms_'+yearColumnIndex">No. of House</th>
                                <th class="sticky-table-data-top-second" :key="year+'no_of_beds_'+yearColumnIndex">No. of Pax</th>
                            </template>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(column,key) in rows">
                            <tr :key="key">
                                <th class="sticky-table-data-right-first" :rowspan="column.district ==='Total'?'3':'4'">{{column.district}}</th>
                            </tr>
                            <template v-for="(subColumn,index) in column.type">
                                <tr :key="column.district+index">
                                    <th class="sticky-table-data-right-second">{{subColumn.type}}</th>
                                    <template v-for="(superSubColumn,count) in subColumn.values">
                                        <td :key="column.district+subColumn.type+'houses'+count+superSubColumn.years">
                                            {{superSubColumn.numberOfAffiliatedHouse}}
                                        </td>
                                        <td :key="column.district+subColumn.type+'rooms'+count+superSubColumn.years">
                                            {{superSubColumn.numberOfRoom}}
                                        </td>
                                        <td :key="column.district+subColumn.type+'beds'+count+superSubColumn.years">
                                            {{superSubColumn.numberOfBed}}
                                        </td>
                                    </template>
                                </tr>
                            </template>
                        </template>
                        <tr :key="key" class="headerBackground">
                            <th colspan="2">Total</th>
                            <template v-for="(totalColumn,indexColumn) in total">
                                <td :key="indexColumn+'numberOfAffiliatedHouse'+totalColumn.years">
                                    {{totalColumn.numberOfAffiliatedHouse}}
                                </td>
                                <td :key="indexColumn+'numberOfRoom'+totalColumn.years">
                                    {{totalColumn.numberOfRoom}}
                                </td>
                                <td :key="indexColumn+'numberOfBed'+totalColumn.years">
                                    {{totalColumn.numberOfBed}}
                                </td>
                            </template>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="full-div text-center">
                <v-progress-circular v-if="!tableDataLoaded"
                                     size="70"
                                     width="7"
                                     color="purple"
                                     indeterminate
                                     class="text-xl-center"></v-progress-circular>
            </div>

            <v-snackbar v-model="snackbar.snackbar"
                        color="success"
                        :timeout="snackbar.timeout"
                        top>
                {{ callbackResponse.message }}
                <v-btn dark
                       text
                       @click="snackbar.snackbar = false">
                    Close
                </v-btn>
            </v-snackbar>
        </v-container>
    </v-content>
</template>

<script>
    import axios from "axios";
    import JsonExcelVue from "@/components/ImportExport/JsonExcel.vue"

    /*import moment from 'vue-moment'*/

    export default {
        name: "MountaineeringHistoryList",
        props: [
            'moduleName'
        ],
        components: {JsonExcelVue},
        computed: {
            customYear() {
                let year = []
                for (let i = 0; i < 99; i++) {
                    year.push(`20${i < 10 ? '0' : ''}${i}`)
                }
                return year
            },
            reportIsMonthWise() {
                return this.serverParams.ReportType === "monthwise";
            },
            items() {
                return [
                    {
                        text: this.$t('reports'),
                        exact: true,
                        to: '/reportModules'
                    },
                    {
                        text: this.$t('home_stay_report'),
                        disabled: true,
                        exact: true
                    }
                ]
            }
        },
        data() {
            return {
                tab: 'tab-monthlyReport',
                callbackResponse: {
                    timeout: 6000
                },
                ReportTypes: [
                    {
                        id: "yearwise", value: "yearwise"
                    },
                    {
                        id: "monthwise", value: "monthwise"
                    }
                ],
                total: [],
                rows: [],
                tableDataLoaded: true,
                yearsBetween: [],
                dialogDelete: false,
                deleteFormData: [],
                formdata: [],
                historyData: {
                    totalRows: 0,
                    editItemIndex: null
                },
                serverParams: {
                    search: "",
                    columnFilters: {},
                    sort: [
                        {
                            field: "id",
                            type: "desc"
                        }
                    ],
                    page: 1,
                    perPage: 10,
                    FromDate: null,
                    ToDate: null,
                    ReportType: ""
                },
                snackbar: {
                    color: '',
                    snackbar: false,
                    text: '',
                    timeout: 6000,
                    isLoading: false,
                    formModal: false,
                    createFormComponent: false,
                    editFormComponent: false,
                    menu1: false,
                    menu2: false,
                    dynamicComponent: {
                        current: 'HistoryForm'
                    }
                },
                customHeader:[]
            };
        },
        methods: {
            fetchData(){
                this.customHeader=[
                    {
                        name:'District'
                    },
                    {
                        name:'Type'
                    }
                ]
                this.total.map(response=>{
                    this.customHeader.push({
                        name: response.years,
                        children:['No. of Affiliated Homestay','No. of House','No. of Pax']
                    })
                })
                const customData = []
                this.rows.map(row=>{
                    let cData = [];
                    cData.title = row.district
                    cData.nestedData = row.type.map(res => {
                        let arr =[]
                        arr.push(res.type);
                        res.values.map(value=>{
                            arr.push(...[value.numberOfAffiliatedHouse,value.numberOfRoom,value.numberOfBed])
                        })
                        return arr;
                    })
                    customData.push(cData)  
                })
                return customData
            },
            getNow: function () {
                const today = new Date();
                const date = today.getFullYear();
                /* const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();*/
                this.serverParams.FromDate = date;
                this.serverParams.ToDate = date;
            },
            formResponse(data) {
                console.log({ data })
                if (data.message) {
                    this.snackbar.formModal = false
                    this.snackbar.createFormComponent = false;
                    this.dialogDelete = false;
                    this.snackbar.editFormComponent = false;
                    this.callbackResponse = data;
                    this.snackbar.snackbar = true
                    this.loadItems();
                } else {
                    this.snackbar.createFormComponent = false;
                    this.dialogDelete = false;
                    this.snackbar.editFormComponent = false;
                    this.snackbar.formModal = false
                }
            },

            async onDownload() {
                let param = {

                    ToDate: this.serverParams.ToDate + '-12-31',
                    FromDate: this.serverParams.FromDate + '-01-01'
                };
                //await axios({
                //    url: 'Report/DownloadSample',
                //    method: 'POST',
                //    responseType: 'blob'
                //}).then((response) => {
                //    const url = window.URL.createObjectURL(new Blob([response.data]));
                //    const link = document.createElement('a');
                //    link.href = url;
                //    link.setAttribute('download', 'HotelFile.xlsx'); //or any other extension
                //    document.body.appendChild(link);
                //    link.click();
                //});

                await axios({
                    url: 'Hotel/DownloadHomeStayMonthlyYearlyReport',
                    method: 'POST',
                    responseType: 'blob',
                    data: param
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'HomeStayMonthlyYearlyReportFile.xlsx'); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                });
            },

            dialogueClose() {
                this.historyData.editItemIndex = null
                this.snackbar.formModal = false
                this.snackbar.createFormComponent = false
                this.snackbar.editFormComponent = false
            },

            async loadItems() {
                this.snackbar.isLoading = true;
                let param = {
                    ToDate: this.serverParams.ToDate + '-12-31',
                    FromDate: this.serverParams.FromDate + '-01-01'
                };
                this.tableDataLoaded = false
                let years = parseInt(this.serverParams.ToDate.slice(0, 4)) - parseInt(this.serverParams.FromDate.slice(0, 4))
                let startYear = parseInt(this.serverParams.FromDate.slice(0, 4))
                this.yearsBetween = []
                for (let year = 0; year <= years; year++) {
                    this.yearsBetween.push(startYear + year);
                }
                axios.post('Hotel/GetHomeStayReport', param)
                    .then(response => {
                        this.rows = response.data[0].data;
                        this.total = response.data[0].total;
                        this.snackbar.loading = false;
                        this.tableDataLoaded = true
                    })
                    .catch(err => {
                        console.log(err)
                    });
            },

            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },

            onPageChange(params) {
                this.updateParams({ page: params.currentPage });
                this.loadItems();
            },

            onPerPageChange(params) {
                this.updateParams({ perPage: params.currentPerPage == -1 ? this.totalRecords : params.currentPerPage });
                this.loadItems();
            },

            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadItems();
            },

            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },

            addModal() {
                this.snackbar.formModal = true
                this.snackbar.createFormComponent = true
            },

            editItem(item) {
                this.snackbar.formModal = true
                this.snackbar.editFormComponent = true
                this.historyData.editItemIndex = item.personInformationID
            },

            deleteItem(props) {
                this.dialogDelete = true;
                this.deleteFormData.url = "Mountain/DeleteHistoryInformationByIDAsync/" + props.personInformationID;
            },
        },
        mounted() {
            this.getNow()
        },

    }
</script>

<style scoped lang="scss">
    $blue: #265b93;

    .myCustomTable {
        overflow-x: auto;
        overflow-y: auto;
        display: block;
        width: fit-content;
        max-width: 100%;
        max-height: 40rem;

        thead {
            background-color: $blue;
            color: white;
        }

        tbody {
            height: 100px; /* Just for the demo          */
            overflow-y: auto; /* Trigger vertical scroll    */
        }

        td, th {
            padding: 1rem
        }

        border: 1px solid #eee;

        tr, td, th {
            border: 1px solid #eee;
        }
    }

    .table-container {
        width: 100%;
    }

    .headerBackground {
        background-color: $blue;
        color: white;
    }

    .sticky-table-data-top-first {
        position: sticky;
        top: 0;
        background-color: $blue;
        z-index: 2;
    }

    .sticky-table-data-top-second {
        position: sticky;
        top: 3.5rem;
        background-color: $blue;
        z-index: 2;
    }

    .sticky-table-data-right-first {
        position: sticky;
        left: 0;
        background-color: white;
        z-index: 1;
    }

    .sticky-table-data-right-second {
        position: sticky;
        left: 8.8rem;
        background-color: white;
        z-index: 1;
    }

    .bg-blue {
        background-color: $blue !important;
    }

    .z-4 {
        z-index: 4 !important;
    }
    //table thead th {
    //  position: sticky;
    //  top: 0;
    //  z-index: 1;
    //}
    //table tbody th {
    //  position: relative;
    //}
    //table thead th:first-child {
    //  position: sticky;
    //  left: 0;
    //  z-index: 2;
    //  background-color: $blue;
    //}
    //table thead th:nth-child(1) {
    //  position: sticky;
    //  left: 20px;
    //  z-index: 2;
    //  background-color: $blue;
    //}
    //table tbody th:nth-child(0){
    //  position: sticky;
    //  left: 0;
    //  z-index: 1;
    //}
    //table tbody th:nth-child(1){
    //  position: sticky;
    //  left: 50px;
    //  z-index: 1;
    //}
</style>
